<!--
 * @Author: your name
 * @Date: 2021-05-14 21:25:49
 * @LastEditTime: 2021-05-19 16:10:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\views\staffadd\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation msg="添加员工">
      <div @click="addmeth" class="saveclass">保存</div>
    </navigation>
    <div class="themsgclass">
      <div class="mcl2 mcl3">
        <div>员工姓名<span class="xh">*</span></div>
        <myname
          :stylemsg="stylemsg"
          :ishaveto="true"
          v-model="staff_name"
          filter="name"
          placeholder="请输入员工姓名"
        ></myname>
      </div>
      <div class="xingbc">
        <div>性别<span class="xh">*</span></div>
        <div class="xingbc2">
          <div @click="sex = 1" :class="sex == 1 ? 'sele1' : 'sele2'">男</div>
          <div @click="sex = 2" :class="sex == 2 ? 'sele1' : 'sele2'">女</div>
        </div>
      </div>
      <div @click="showtime = true" class="mcl2">
        <div>出生年月<span class="xh">*</span></div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          readonly="readonly"
          v-model="birthday"
          type="text"
          placeholder="请选择出生年月"
        />
      </div>
      <div class="mcl2 mcl3">
        <div>手机号<span class="xh">*</span></div>
        <myname
          :stylemsg="stylemsg"
          :ishaveto="true"
          v-model="mobile"
          filter="phone"
          placeholder="请输入手机号"
        ></myname>
      </div>
      <div class="mcl2 mcl3">
        <div>身份证号</div>
        <myname
          :stylemsg="stylemsg"
          v-model="id_no"
          filter="idcard"
          placeholder="请输入身份证号"
        ></myname>
      </div>
      <div class="mcl2">
        <div>
          邮箱<span style="color: #ff4800" v-show="!isemail"
            >(邮箱格式不正确)</span
          >
        </div>
        <input
          v-model="email"
          class="oncl"
          type="text"
          placeholder="请输入邮箱"
        />
      </div>
      <div @click="$refs.addressid.addressmeth('', '')" class="mcl2">
        <div>居住所在地区</div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          v-model="area"
          readonly="readonly"
          type="text"
          placeholder="请选择居住所在地"
        />
      </div>
      <div @click="showshow = true" class="mcl2">
        <div>岗位<span class="xh">*</span></div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          readonly="readonly"
          v-model="position"
          type="text"
          placeholder="请选择岗位"
        />
      </div>
      <div @click="showmm = true" class="mcl2">
        <div>政治面貌<span class="xh">*</span></div>
        <div class="rgc">
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <input
          readonly="readonly"
          v-model="politics"
          type="text"
          placeholder="请选择岗位"
        />
      </div>
    </div>
    <!-- 求职岗位 -->
    <van-popup
      v-model="showshow"
      round
      position="bottom"
      :style="{ height: '100%' }"
    >
      <station></station>
    </van-popup>
    <!-- 居住地 -->
    <addresses ref="addressid" @addressreturn="addressreturn"></addresses>
    <div class="fenxiang">
      <button @click="addmeth">提交</button>
    </div>
    <!-- <van-calendar
      v-model="showtime"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="onConfirm"
    /> -->
    <van-popup v-model="showtime" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择出生日期"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirm"
      />
    </van-popup>

    <van-popup v-model="showmm" round position="bottom">
      <van-picker
        title="政治面貌"
        show-toolbar
        :columns="mmarray"
        @cancel="showmm = false"
        @confirm="mmgmeth"
      />
    </van-popup>
    <div class="nomsgclass"></div>
  </div>
</template>
<script>
import addresses from "../../components/address";
import station from "../../components/station/index";
import eventbus from "../../../util/eventbus.js";
export default {
  components: {
    station,
    addresses,
  },
  data() {
    return {
      staff_name: "", //姓名
      mobile: "", //手机号
      birthday: "", //出生年月
      id_no: "", //身份证
      email: "", //电子邮箱
      position: "", //岗位
      province: "", //省
      city: "", //市
      district: "", //区
      politics: "", //政治面貌
      showshow: false,
      area: "",
      isemail: true,
      okup: true, //是否可以提交
      stylemsg: `
            font-size: 0.34rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 0.48rem;
            width: 100%;
            margin-top: 0.15rem;
            padding-bottom:.42rem;
            border-top:none;
            border-right:none;
            border-left:none;
      `,
      sex: "1", //1:男 2:女
      showtime: false,
      showmm: false,
      mmarray: ["群众", "党员", "民主党派"],
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
    };
  },
  mounted() {
    //求职岗位返回的值
    eventbus.$on("confirmstation", (e) => {
      this.showshow = false;
      this.position = e;
    });
    eventbus.$on("cancelstation", () => {
      this.showshow = false;
    });
  },
  watch: {
    email() {
      if (
        /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(
          this.email
        ) &&
        this.email
      ) {
         
        this.isemail = true;
        this.okup = true;
      } else {
        if (this.email == "") {
          this.isemail = true;
          this.okup = true;
        } else {
          this.isemail = false;
          this.okup = false;
        }
      }
    },
  },
  methods: {
    //出生年月
    confirm(msg) {
      this.birthday = msg.getFullYear()+'-'+msg.getMonth()+'-'+msg.getDate();
      this.showtime = false;
    },
    //政治面貌
    mmgmeth(msg) {
      this.showmm = false;
      this.politics = msg;
       
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.showtime = false;
      this.birthday = this.formatDate(date);
    },
    //新增员工
    addmeth() {
      this.addstaffmeth();
    },
    //地址返回值
    addressreturn(msg, index) {
      this.area = msg[0].name + "/" + msg[1].name + "/" + msg[2].name;
      this.province = msg[0].name; //省
      this.city = msg[1].name; //市
      this.district = msg[2].name; //区
    },
    //添加新员工
    addstaffmeth() {
      if (!this.staff_name) {
        this.$toast("请输入正确姓名");
        return;
      }
      if (!this.mobile) {
        this.$toast("请输入正确手机号");
        return;
      }
      if (!this.position) {
        this.$toast("请选择岗位");
        return;
      }
      if (!this.okup) {
        this.$toast("请修改邮箱信息");
        return;
      }
      if (!this.birthday) {
        this.$toast("请选择出生年月");
        return;
      }
      if (!this.politics) {
        this.$toast("请选择政治面貌");
        return;
      }
      this.$http
        .post("/firm/v1/profile/add", {
          reqType: "profile",
          staff_name: this.staff_name,
          id_no: this.id_no,
          mobile: this.mobile,
          email: this.email,
          position: this.position,
          entry_time: "",
          province: this.province,
          city: this.city,
          district: this.district,
          address: "",
          status: "1",
          resume_name: "",
          resume: "",
          sex: this.sex == 1 ? "男" : "女",
          birthday: this.birthday,
          politics: this.politics,
        })
        .then((res) => {
           
          this.$toast("添加成功");
          this.$router.go(-1);
        });
    },
  },
};
</script>
<style scoped>
.sele1,
.sele2 {
  border: 1px solid #fe550a;
  padding: 0.13rem 0.38rem;
}
.sele1 {
  color: white;
  background-color: #fe7f46;
}
.sele2 {
  color: #fe7f46;
}
.xingbc2 {
  display: flex;
  align-items: center;
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #ffffff;
}
.xingbc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.66rem 0;
  font-size: 0.28rem;
  line-height: 0.48rem;
  color: #676767;
  border-bottom-color: #d6d6d6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0.27rem;
}
.nomsgclass {
  height: 1.5rem;
}
.nomsgclass {
  height: 1.5rem;
}
.fenxiang {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 95%;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  background-color: #ffffff;
  border-top-color: #dfdfdf;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.29rem 2.5%;
}
.fenxiang > button:first-child {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border: 0.03rem solid #ff5f19;
  border-radius: 0.16rem;
  padding: 0.25rem 0;
  text-align: center;
  min-width: 90%;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  margin-left: 50%;
  transform: translateX(-50%);
}
.fenxiang > button:nth-child(2) {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  border: none;
  padding: 0.28rem 0;
  text-align: center;
  width: 45%;
}
.allmsgclass >>> .van-popup--bottom.van-popup--round {
  border-radius: 0;
}
.xh {
  color: #ff1f1f;
  font-size: 0.3rem;
  margin-left: 0.05rem;
}
.rgc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.themsgclass {
  padding: 0 0.4rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.oncl {
  margin-top: 0.15rem;
}
.allmsgclass .mcl3 {
  padding-bottom: 0;
  border-bottom: none;
}
.mcl2 {
  padding: 0.42rem 0;
  border-bottom-color: #d6d6d6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0.28rem;
}
.mcl2 input {
  border: none;
}
.mcl2 > :first-child {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  line-height: 0.48rem;
}
.mcl2 > input {
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.48rem;
  width: 100%;
}
.saveclass {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
}
</style>
